
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import Users from '../sections/home/users';
import DashboardIcon from '../assets/svgs/dashboardIcon';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";

// home main section container.
const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 0px 88px",
    backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
    height: '100vh'
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));

const HeadingContentSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Home = () => {
    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.home}`} </title>
            </Helmet>
            <MainSection>
                {/* Heading and filters */}
                <HeadingSection>
                    <HeadingContentSection>
                        <Box sx={{
                            widht: '16px',
                            height: '16px',
                            mt: "-10px",
                        }}>
                            <DashboardIcon />
                        </Box>
                        <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                            Dashboard
                        </Typography>
                    </HeadingContentSection>
                </HeadingSection>

                <Users />

            </MainSection>
        </>
    )
}
export default Home;