// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, Button } from '@mui/material';
import BillIcon from '../assets/svgs/bill';
import BillingForm from '../sections/billing/billingForm';
import { SnackbarProvider } from 'src/components/snackbar';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";

const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 20px 88px",
    backdropFilter: "blur(6px)",
    backgroundColor: "rgba(255, 255, 255, 0.8)"
}));

const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));

const HeadingContentSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
}));



const BillingList = () => {
    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.billing}`} </title>
            </Helmet>
            <SnackbarProvider>


                <MainSection>
                    <HeadingSection>
                        <HeadingContentSection>
                            <Box sx={{
                                widht: '40px',
                                height: '30px',
                               
                            }}>
                                <BillIcon />
                            </Box>
                            <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                                Create Quotation
                            </Typography>
                        </HeadingContentSection>

                    </HeadingSection>

                    <BillingForm />

                </MainSection>
            </SnackbarProvider>
        </>
    )
}
export default BillingList;