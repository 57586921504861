import { io } from 'socket.io-client';
import { getItem } from 'src/lib/cookies';
import { configFile } from 'src/config';

const { getApiUrl } = configFile;

const URL = getApiUrl();

const token = getItem("sessionToken");
const socketInstance = io(URL, {

    path: "/api/socket.io",
    // path: "/socket.io",
    autoConnect: false,
    transports: ['websocket', 'polling'],
    auth: {
        token
    }
});


export default socketInstance;