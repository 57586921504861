// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, Button } from '@mui/material';
import BillIcon from '../assets/svgs/bill';
import BillingForm from '../sections/billing/billingList';
import { SnackbarProvider } from 'src/components/snackbar';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";
import { useNavigate } from 'react-router-dom';


const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 20px 88px",
    backdropFilter: "blur(6px)",
    backgroundColor: "rgba(255, 255, 255, 0.8)"
}));

const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));

const HeadingContentSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
}));

const createQuotation = async (navigate) => {
    navigate("/create_quotation")
}

const Billing = () => {
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.billing}`} </title>
            </Helmet>
            <SnackbarProvider>


                <MainSection>
                    <HeadingSection>
                        <HeadingContentSection>
                            <Box sx={{
                                widht: '40px',
                                height: '30px',
                               
                            }}>
                                <BillIcon />
                            </Box>
                            <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                                Billing
                            </Typography>
                            <Box sx={{
                            display: "flex",
                            gap: "10px",
                            position:'absolute',
                            right:0,
                          marginRight:'30px'
                        }}>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={() => createQuotation(navigate)}
                                
                            >Create Quotation</Button>
                        </Box>
                        </HeadingContentSection>

                    </HeadingSection>

                    <BillingForm />

                </MainSection>
            </SnackbarProvider>
        </>
    )
}
export default Billing;