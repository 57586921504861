import { useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'src/components/snackbar';

// import { useMockedUser } from 'src/hooks/use-mocked-user';

import Iconify from 'src/components/iconify';
import { jwtDecode } from 'jwt-decode';
import { getItem } from 'src/lib/cookies';
import { Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

export default function KanbanDetailsCommentInput({ handleComment }) {
  // const { user } = useMockedUser();
  const { enqueueSnackbar } = useSnackbar();
  const { role, userName, email, profileUrl } = jwtDecode(localStorage.getItem("token"))
  const [commentText, setCommentText] = useState("");
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleClick = () => {
    if (commentText) {
      return enqueueSnackbar("Only one input is allowed at a time. Please remove either the text message or the selected file before sending.", {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: "error"
      });
    }
    fileRef.current.click();
  }

  const fileChange = (file) => {
    const validImageType = ['image/jpg', 'image/png', 'image/jpeg']
    if (!file) {
      return setFile(null);
    }
    if (!validImageType.includes(file.type)) {
      return enqueueSnackbar("Invalid image Type.", {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: "error"
      });
    }
    setFile(file);
    enqueueSnackbar(`${file.name} selected successfully`, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
  }

  const handleSubmit = () => {
    if (!commentText && !file) {
      return enqueueSnackbar("Invalid comment input.", {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: "error"
      });
    }
    if (commentText) {
      handleComment("text", commentText);
      setCommentText("");
      return
    }
    if (file) {
      handleComment("image", file);
      setCommentText("")
      setFile(null)
    }
  }

  const handleTextChange = (value) => {
    if (file) {
      return enqueueSnackbar("Only one input is allowed at a time. Please remove either the text message or the selected file before sending.", {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: "error"
      });
    }
    setCommentText(value)
  }

  const handleFileClose = () => {
    setFile(null);
  }

  const user = {
    "id": email,
    "displayName": userName,
    "email": email,
    "password": "demo1234",
    "photoURL": profileUrl,
    "phoneNumber": "+91 ",
    "country": "India",
    "address": "Jaipur",
    "state": "Rajasthan",
    "city": "Jaipur",
    "zipCode": "302022",
    "about": "Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.",
    "role": role,
    "isPublic": true
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        py: 3,
        px: 2.5,
      }}
    >
      <Avatar src={user?.photoURL} alt={user?.displayName}>
        {user?.displayName?.charAt(0).toUpperCase()}
      </Avatar>

      <Paper variant="outlined" sx={{ p: 1, flexGrow: 1, bgcolor: 'transparent' }}>
        <InputBase fullWidth multiline rows={2} placeholder="Type a message" sx={{ px: 1 }} value={commentText} onChange={(e) => handleTextChange(e.target.value)} />

        <Stack direction="row" alignItems="center">
          <Stack direction="row" flexGrow={1}>
            {!file && <Tooltip title="Select image.">
              <IconButton onClick={() => handleClick()}>
                <Iconify icon="solar:gallery-add-bold" />
              </IconButton>
            </Tooltip>}

            <input
              type="file"
              ref={fileRef}
              accept='image/*'
              onChange={(e) => fileChange(e.target.files[0])}
              hidden
            />
            {file && <Tooltip title="Unselect image.">
              <IconButton onClick={() => handleFileClose()}>
                <Iconify icon="carbon:close-outline" />
              </IconButton>
            </Tooltip>}
          </Stack>

          <Button variant="contained" onClick={handleSubmit} >Comment</Button>
        </Stack>
      </Paper >
    </Stack >
  );
}
