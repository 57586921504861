import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { axiosGetCall } from '../../services/apisCall';
import { CustomSkeleton } from '../../sections/skeleton/skeletonLoader';


const columns = [
    {id:'name',label:"User Name",minWidth: 170},
    {id:'email',label:"Email ID",minWidth: 170},
    { id: 'total_accept', label: 'Total Accepted', minWidth: 170 },
    { id: 'total_pass', label: 'Total Passed', minWidth: 150 }
];

export default function AmAnalysis() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [networkError, setNetworkError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [load, setLoad] = React.useState(true)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAMDetails = async () => {
        const skip = rowsPerPage * page;
        try {
            // const params = { skip, limit: rowsPerPage, flag: 1 };
            setNetworkError(false);
            const { result, totalCount } = await axiosGetCall("amAnalysis");
            result?.sort((a,b)=>b.total_accept-a.total_accept)
            setRows(result);
            setCount(totalCount);
        } catch (error) {
            setNetworkError(true);
            setRows([]);
            setCount(0);
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            getAMDetails();
            setLoading(false);
        };

        fetchData();
    }, []);
   
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 219px)' }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align="center"
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        <TableBody>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                {columns.map(({ id, align }) => (
                                    <TableCell
                                        key={id}
                                        align={align}
                                        sx={{
                                            color: 'grey.300',
                                            borderBottom: '1px solid',
                                            borderColor: 'grey.800',
                                        }}
                                    >
                                        <CustomSkeleton variant={"text"} component={"h6"} width={"100%"} />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                        {rows?.map((row) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.userName}>
                                {columns?.map((column) => {
                                    let value = row[column.id] !== undefined && row[column.id] !== null ? row[column.id] : '--';
                                    return (
                                        <TableCell key={column.id} align="center">
                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                    
                    )}
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Paper>
    );
}

