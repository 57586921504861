import React from 'react';
import moment from 'moment';
import propTypes from 'prop-types'
import commonMessage from '../../utils/commonMessage.json'

//mui material
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Chip, Box, Typography, Icon } from '@mui/material';
import { styled } from '@mui/material/styles';

// mui icons
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppBadIcon from '@mui/icons-material/GppBad';
import HistoryIcon from '@mui/icons-material/History';
import VerifiedIcon from '@mui/icons-material/Verified';

//components
import NetworkError from '../Error/networkError';
import { CustomSkeleton } from '../../sections/skeleton/skeletonLoader';
import Tooltip from '@mui/material/Tooltip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { copyToClipBoard, nameFormater } from 'src/utils/common';
import { renderErrorMessage, renderSuccessMessage } from 'src/lib/toastMessage';
import { LoadingButton } from '@mui/lab';
import Iconify from '../iconify';
import { axiosGetCall } from 'src/services/apisCall';
import CircularProgressWithLabel from './circleProgressBar';
import gLogin from './gLogo.png';

// Show table filtes.
const TableFilterSection = styled('div')(() => ({
  width: 'auto',
  padding: '16px 16px 16px 16px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

export const PaginationTable = ({
  page = 0,
  tableFilter,
  ActionJsx,
  ActionModals,
  setPage,
  rowsPerPage = 10,
  setRowsPerPage,
  setLoad,
  count = 0,
  error = false,
  loading,
  setLoading,
  rows,
  columns,
  size = "small",
  calculatedHeight = 0
}) => {

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
    setLoad(true)
    setLoading(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoad(true)
    setLoading(true)
  };

  const chipColors = {
    approved: { color: '#50AB64', backgroundColor: 'rgba(40, 167, 69, 0.20)', },
    partialapproved: { color: '#0000FF', backgroundColor: 'rgba(100, 149, 237, 0.20)', },
    rejected: { color: '#E2533F', backgroundColor: 'rgba(236, 64, 64, 0.20)', },
    active: { color: '#50AB64', backgroundColor: 'rgba(40, 167, 69, 0.20)', },
    pending: { color: '#D19244', backgroundColor: 'rgba(209, 146, 68, 0.20)', },
    deActivate: { color: '#E2533F', backgroundColor: 'rgba(236, 64, 64, 0.20)', },
  }

  const statusMapper = (value) => {
    const map = {
      active: "Active",
      deActivate: "De-Activate",
      pending: "Pending",
      approved: "Approved",
      partialapproved: "Partial Approved",
      rejected: "Rejected",

    }
    if (map[value]) return map[value]
    return value
  }

  const createStatusElement = (value) => {

    const statusChip = (value) => (
      <Chip label={statusMapper(value)} sx={{
        p: '2px 0px 2px 0px',
        height: 'auto',
        borderRadius: '5px',
        fontWeight: 500,
        ...chipColors?.[value],
        "&:hover": {
          ...chipColors?.[value],

        },
        size: 'medium'
      }} />
    )

    const allStatus = {
      active: statusChip,
      pending: statusChip,
      deActivate: statusChip,
      approved: statusChip,
      partialapproved: statusChip,
      rejected: statusChip

    }
    if (typeof allStatus[value] === 'function') {
      return allStatus?.[value](value);
    } else {
      console.error(`Status '${value}' is not recognized.`);
      return null;
    }
  }

  const statusJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {createStatusElement(value)}
    </TableCell>
  )

  const createKYCStatus = (value) => {
    const chipColors = {
      finish: {
        backgroundColor: 'rgba(40, 167, 69, 0.20)',
        color: '#50AB64'
      },
      inprogress: {
        backgroundColor: 'rgba(209, 146, 68, 0.20)',
        color: '#D19244'
      }
    };

    const IconComponent = value === "finish" ? VerifiedIcon : HistoryIcon;

    const statusChip = (
      <Tooltip title={value === "finish" ? "All the kyc documents are uploaded." : "All the kyc documents are not uploaded!"}>
        <IconComponent
          style={{
            color: chipColors[value]?.color ? chipColors[value]?.color : '#D19244',
            height: '15px',
            width: '15px'
          }} />
      </Tooltip>
    );

    return statusChip;
  };

  const nextStep = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>

      {createKYCStatus(value)}
    </TableCell>
  )

  const hierarchy = (id, align, value) => {
    const emailList = value?.join(", ") ? value?.join(", ") : "N/A"

    return (
      <TableCell align={align} key={id} sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
        <Tooltip title={emailList} arrow>
          <InfoOutlinedIcon style={{
            cursor: 'pointer', color: '#D19244', height: '18px',
            width: '18px'
          }} />
        </Tooltip>
      </TableCell>
    );
  };

  const createdBy = (id, align, value) => {
    let createdBy = value;
    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}>

        {createdBy}
      </TableCell>)
  }
  const documentApprovedStatus = (id, align, value, userId, totalCount, approvedCount, ...others) => {
    function upperCase(str) {
      return str
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (char) => char.toUpperCase());
    }

    function approvedIcons() {
      return others[0]?.documents?.filter(doc => doc?.status === 'approved')?.map(doc => {
        const docName = upperCase(doc.name);
        return (

          <Tooltip title={docName} arrow placement="top">
            <img
              key={doc.name}
              src={`/images/${doc.name}.png`}
              alt={doc.name}
              style={{ width: 17, height: 17 }}
            />
          </Tooltip>
        )
      }
      );
    }
    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '5px'
          }}>
          {approvedIcons()?.map((icon, index) =>
          (
            <span key={index} style={{ display: 'inline-block' }}>
              {icon}
            </span>
          )
          )}
        </Box>


      </TableCell>
    );
  };


  const approvedDocs = (id, align, value, userId, totalCount, approvedCount, ...others) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {(others[0].totalDocs === 0 && others[0].totalApprovedDocs === 0)
        ? '0%'
        : `${Math.round((others[0].totalApprovedDocs / others[0].totalDocs) * 100)}%`}
    </TableCell>
  )

  const passwordChip = (id, align, isPassword, ...other) => {
    const row = other[3];
    const { email } = row;
    const [password, setPassword] = React.useState("");
    const [passwordLoading, setPasswordLoading] = React.useState(false);
    const [passwordEmail, setPasswordEmail] = React.useState("");
    const [passwordTimer, setPasswordTimer] = React.useState(100);
    const [passwordInterval, setPasswordInterval] = React.useState('');

    const fetchPassword = async () => {
      try {
        setPasswordTimer(100)
        setPasswordEmail(email);
        setPasswordLoading(true);
        const { userPassword } = await axiosGetCall("getUserPassword", { email });
        setPassword(userPassword);
        copyToClipBoard(userPassword);
        renderSuccessMessage("Password copied to clipboard.")

        const interval = setInterval(() => {
          //     setPasswordTimer((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
          setPasswordTimer((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 10));
          // }, 1000);
        }, 1000);

        setPasswordInterval(interval)

      } catch (error) {
        console.log(error)
        renderErrorMessage(error?.message || "Network error!")
      } finally {
        setPasswordLoading(false);
      }
    }

    React.useEffect(() => {
      if (!passwordTimer) {
        clearInterval(passwordInterval);
        setPasswordEmail("")
        setPasswordInterval("")
        setPassword("")
      }

      //eslint-disable-next-line
    }, [passwordTimer])

    return (
      isPassword ?
        <TableCell
          key={id}
          align={align}
          sx={{
            color: 'grey.700',
            borderBottom: '0.5px dashed',
            borderColor: 'grey.300',
          }}
        >
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '7px',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            {password && passwordEmail === row?.email && <>
              {password}
            </>
            }

            <Tooltip title={password && passwordEmail === row?.email ? "Password Copied!" : "Copy Password"} componentsProps={{
              tooltip: {
                sx: {
                  // bgcolor: 'grey.300',
                },
              },
            }}>
              <LoadingButton
                loading={passwordEmail === row?.email ? passwordLoading : false}
                loadingPosition="start"
                size={'small'}
                color={passwordEmail === row?.email && password ? 'success' : 'primary'}
                sx={{
                  borderRadius: '50%',
                  minWidth: 'auto'
                }}
                onClick={() => fetchPassword(row?.email)}
              >
                {!(passwordEmail === row?.email && passwordLoading) && <Iconify icon="prime:copy" width={"20px"} />}
              </LoadingButton>
            </Tooltip>

            {/* {passwordEmail === row?.email && password &&
                <Tooltip title={`For security purpose password will automaticaly hide in ${passwordTimer / 10} `} componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'grey.300',
                        },
                    },
                }}>
                    <CircularProgressWithLabel value={setPasswordTimer} />
                </Tooltip>} */}
            {passwordEmail === row?.email && password &&

              <CircularProgressWithLabel value={passwordTimer} />
            }

          </Box>
        </TableCell>
        : <TableCell
          key={id}
          align={align}
          sx={{
            color: 'grey.700',
            borderBottom: '0.5px dashed',
            borderColor: 'grey.300',
          }}
        >--</TableCell>
    )
  }


  const dateFormatJsx = (id, align, value, ...other) => {
    const date = moment(value);
    const formattedDate = date.format('D MMM, YYYY');

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}>
        <>
          <Box>{formattedDate}</Box>

        </>
      </TableCell>)
  }

  const roleFormatJsx = (id, align, value) => {
    const roleFormatMapper = {
      superAdmin: "Super-Admin",
      admin: "Admin",
      agent: "Agent"
    }

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}>
        {roleFormatMapper[value]}
      </TableCell>
    )

  }

  const defaultRowJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {value || "--"}
    </TableCell>
  )

  const userIDJsx = (id, align, value, ...other) => {
    const row = other[3];

    const { voiceInfo = {}, products = [] } = row;
    const { userId = "", userType = "" } = voiceInfo;

    const toolTipComponent = (
      <Box sx={{
        display: "flex",
        gap: "10px",
        width: "100%",
        flexDirection: "row",
        alignItems: 'center',
        padding: "0px 10px"
      }}>
        <Typography sx={{ fontSize: '12px' }}>VB</Typography>
        <Typography sx={{ fontSize: '12px' }}>---{`>`}</Typography>
        <Box sx={{ display: "flex", gap: "5px" }}>

          <Typography sx={{ fontSize: '12px' }}>User ID </Typography>
          <Typography sx={{ fontSize: '12px' }}>:</Typography>
          <Tooltip title="Click here for Copy.">

            <Typography
              sx={{
                cursor: "pointer",
                fontSize: '12px'
              }}
              onClick={() => {
                copyToClipBoard(userId);
                renderSuccessMessage('User ID copied!');
              }}>{userId}</Typography>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", gap: "5px" }}>
          <Typography sx={{ fontSize: '12px' }}>Type</Typography>
          <Typography sx={{ fontSize: '12px' }}>:</Typography>
          <Typography sx={{ fontSize: '12px' }}>{userType === "reseller" ? "Reseller" : "User"}</Typography>
        </Box>
      </Box>
    )

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}>
        <Box sx={{
          display: "flex",
          gap: "10px",
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {value}

          {products.includes("Broadcast") && userId &&
            <Tooltip
              title={toolTipComponent}
              enterTouchDelay={0}
              // componentsProps={{
              //   tooltip: {
              //     sx: {
              //       color: "text.primary",
              //       backgroundColor: "background.paper",
              //       fontSize: "2em"
              //     }
              //   }
              // }}
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: 'none', // Allow tooltip to adjust its width
                    // backgroundColor: 'background.paper',
                    // color: 'text.primary',
                    boxShadow: 1,
                    fontSize: 10,
                    padding: 1,
                    borderRadius: 1,
                  },
                },
                arrow: {
                  sx: {
                    color: 'background.paper',
                  },
                },
              }}
            >
              <InfoOutlinedIcon sx={{ width: '20px', color: 'primary.main' }} />
            </Tooltip>
          }
        </Box>
      </TableCell>
    )
  }



  const googleLoginJsx = (id, align, value) => (<TableCell
    key={id}
    align={align}
    sx={{
      color: 'grey.700',
      borderBottom: '0.5px dashed',
      borderColor: 'grey.300',
    }}>
    {value ? "Yes" : "No"}
  </TableCell>
  )

  const verifiedJsx = (id, align, value, ...other) => {
    const row = other[3];
    function show() {
      if (row?.verified) {
        if (row?.verifiedForcefully) {
          return <VerifiedUserIcon color='warning' sx={{ height: '16px', width: '16px' }} />
        }
        else {
          return <VerifiedUserIcon color='success' sx={{ height: '16px', width: '16px' }} />
        }
      }
      else {
        return <GppBadIcon color='error' sx={{ height: '18px', width: '18px' }} />
      }
    }


    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}>
        <Tooltip title={row?.verified ? (row?.verifiedForcefully ? "Force verified!" : "Verified!") : "Not verified!"}>
          {show()}
        </Tooltip>

      </TableCell>
    )
  }

  const userTypeJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {nameFormater(value || "--")}
    </TableCell>
  )

  const kycByJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {nameFormater(value || "--")}
    </TableCell>
  )

  const fNameUppercase = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.300',
      }}>
      {nameFormater(value) || "--"}
    </TableCell>
  )

  const nameJsx = (id, align, value, ...other) => {
    const row = other[3]
    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.300',
        }}>
        {(nameFormater(value) || "--")}
        {row?.isGoogleLogin ? <img src={gLogin} alt='glogin' style={{ width: '12px', height: '12px' }} /> : ""}
      </TableCell>
    )
  }

  const tableRowData = {
    kycStatus: statusJsx,
    actions: ActionJsx,
    documentApprovedStatus: documentApprovedStatus,
    nextStep: nextStep,
    createdBy: createdBy,
    documents: approvedDocs,
    createdAt: dateFormatJsx,
    role: roleFormatJsx,
    status: statusJsx,
    hierarchy,
    isGoogleLogin: googleLoginJsx,
    verified: verifiedJsx,
    userId: userIDJsx,
    userType: userTypeJsx,
    kycBy: kycByJsx,
    password: passwordChip,
    permission: fNameUppercase,
    name: nameJsx
  }

  return (
    <Paper sx={{
      overflow: 'hidden',
      backgroundColor: 'grey.1000',
      borderRadius: '6px'
    }}>

      <TableFilterSection>
        {tableFilter}
      </TableFilterSection>

      <TableContainer sx={{ maxHeight: window.innerHeight - calculatedHeight }}>
        <Table stickyHeader aria-label="sticky table" size={size}>

          <TableHead sx={{
            position: "sticky"
          }} >
            <TableRow >
              {columns.map(({ id, align, minWidth, label }) => (
                <TableCell
                  key={id}
                  align={align}
                  style={{ minWidth: 10 }}
                  sx={{
                    backgroundColor: 'grey.200',
                    color: 'grey.600',
                  }}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {!loading ?
            <TableBody>
              {rows.map((row) =>
              (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row?.userId || row?.email}
                >
                  {columns.map(({ id, align }) => (tableRowData?.[id] || defaultRowJsx)(id, align, row[id], row["email"] || row["userId"], row["status"] || row["totalDocuments"], row["documentApprovedStatus"], row, row["hierarchy"],))}
                </TableRow>
              )
              )}
            </TableBody>
            :
            <TableBody>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
              >
                {columns.map(({ id, align }) => (
                  <TableCell
                    key={id}
                    align={align}
                    sx={{
                      color: 'grey.300',
                      borderBottom: '1px solid',
                      borderColor: 'grey.800',
                    }}
                  >
                    <CustomSkeleton variant={"text"} component={"h6"} width={"100%"} />
                  </TableCell>
                ))}
              </TableRow>

            </TableBody>
          }
        </Table>
      </TableContainer>

      {count > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            backgroundColor: 'grey.1000',
            color: 'grey.700',
          }}
        />
      )}

      {!(count || loading) &&
        < Box sx={{
          height: {
            xs: "480px",
            sm: "480px",
            md: "480px",
            lg: "480px",
            xl: "670px",
          },
          display: 'flex',
          justifyContent: 'center'
        }}>
          <NetworkError message={!error ? commonMessage?.noDataFound : commonMessage?.networkError} calculatedHeight={calculatedHeight} />
        </Box>
      }
      {ActionModals && <ActionModals />}
    </Paper>
  )
}



PaginationTable.propTypes = {
  page: propTypes.number,
  setPage: propTypes.func.isRequired,
  rowsPerPage: propTypes.number,
  setRowsPerPage: propTypes.func.isRequired,
  setLoad: propTypes.func.isRequired,
  count: propTypes.number,
  error: propTypes.bool,
  loading: propTypes.bool,
  setLoading: propTypes.func,
  rows: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  maxHeight: propTypes.number,
}
