import React, { useState, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Box, TextField, Divider, Select, MenuItem, FormControl, InputLabel, Autocomplete } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PreviewComponent from './preview';
import { useBoolean } from 'src/hooks/use-boolean';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'src/components/snackbar';

const RowTow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const BillingForm = () => {
    const theme = useTheme();
    const confirm = useBoolean();
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [isFocused, setIsFocused] = useState(false);

    const [order, setOrder] = useState({
        fromAddress: {
            fullName: 'Sarv.com',
            email: 'sales@sarv.com',
            contactNumber: '+91-9111-9111-00',
            address: 'IT-10, EPIP RIICO Industrial Area, Sitapura',
            city: 'Jaipur',
            state: 'Rajasthan',
            country: 'India',
            zipCode: '302022'
        },
        toAddress: {
            fullName: '',
            email: '',
            contactNumber: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zipCode: ''
        },
        items: [{ product: '', plan: '', quantity: '', rate: '', taxRate: '', taxAmount: '', discount: '', discountAmount: '', amount: 0 }],
        date: new Date(),
        totalSubTotal: 0, totalDiscount: 0, totalTax: 0, netPayable: 0
    });

    useEffect(() => {
        console.log(order)
    }, [order])

    const [rows, setRows] = useState([])
    const [load, setLoad] = useState(true)
    const [networkError, setNetworkError] = useState(false)

    const validationSchema = Yup.object().shape({
        fromAddress: Yup.object().shape({
            fullName: Yup.string().trim().required('Full Name is required'),
            email: Yup.string().trim().email('Invalid email format').required('Email is required'),
            contactNumber: Yup.string()
                .trim()
                .matches(/^\d{10}$/, 'Contact Number must be a 10-digit number')
                .required('Contact Number is required'),
            address: Yup.string().trim().required('Address is required'),
            city: Yup.string().trim().required('City is required'),
            zipCode: Yup.string().trim().required('Zip Code is required'),
            state: Yup.string().trim().required('State is required'),
            country: Yup.string().trim().required('Country is required'),
        }),
        toAddress: Yup.object().shape({
            fullName: Yup.string().trim().required('Full Name is required'),
            email: Yup.string().trim().email('Invalid email format').required('Email is required'),
            contactNumber: Yup.string()
                .trim()
                .matches(/^\d{10}$/, 'Contact Number must be a 10-digit number')
                .required('Contact Number is required'),
            address: Yup.string().trim().required('Address is required'),
            city: Yup.string().trim().required('City is required'),
            zipCode: Yup.string().trim().required('Zip Code is required'),
            state: Yup.string().trim().required('State is required'),
            country: Yup.string().trim().required('Country is required'),
        })
    });

    const getPlansList = async () => {
        try {
            let params = { skip: 0, limit: 1000 };
            const data = await axiosGetCall("getPlanList", params);
            const { planList: { result }, products } = data;

            let newResult = result.filter(plan => plan.plan_name !== "Free");
            let groupedByProduct = {};

            newResult.forEach(plan => {
                const product = products.find(prod => prod.product_id === plan.product_id);
                if (product) {
                    if (!groupedByProduct[product.product_name]) {
                        groupedByProduct[product.product_name] = [];
                    }
                    groupedByProduct[product.product_name].push({
                        plan_name: plan.plan_name,
                        rate: plan.price
                    });
                }
            });
            setRows(groupedByProduct);
        } catch (error) {
            setNetworkError(true);
            setRows([]);
        }
    };

    useEffect(() => {
        if (load) {
            getPlansList();
        }
        setLoad(false)
    }, [load])

    const inputSx = {
        color: theme.palette.text.primary,
        borderRadius: "6px",
    };

    const handleInputChange = (index, e) => {
        const newItems = [...order.items];
        const { name, value } = e.target;

        if (name === "product") {
            newItems[index].product = value;
            newItems[index].plan = '';
            newItems[index].rate = '';
        } else if (name === "plan") {
            const selectedProductPlans = rows[newItems[index].product];
            const selectedPlan = selectedProductPlans.find(plan => plan.plan_name === value);
            newItems[index].plan = value;
            newItems[index].rate = selectedPlan ? selectedPlan.rate : '';
        } else {
            newItems[index][name] = value;
        }

        if (name === 'rate' || name === 'quantity' || name === 'taxRate' || name === 'discount') {
            const rate = parseFloat(newItems[index].rate) || 0;
            const quantity = parseFloat(newItems[index].quantity) || 0;
            const taxRate = parseFloat(newItems[index].taxRate) || 0;
            const discountRate = parseFloat(newItems[index].discount) || 0;

            const amount = rate * quantity;
            const taxAmount = (amount * taxRate) / 100;
            const discountAmount = (amount * discountRate) / 100;

            newItems[index].amount = amount - discountAmount + taxAmount;
            newItems[index].taxAmount = taxAmount;
            newItems[index].discountAmount = discountAmount;
        }

        setOrder({ ...order, items: newItems });
    };

    const handleRemoveItem = (index) => {
        const newItems = order.items.filter((_, i) => i !== index);
        setOrder({ ...order, items: newItems });
    };

    const calculateTotal = () => {
        const totals = order.items.reduce(
            (acc, item) => {
                const itemSubtotal = item.quantity * item.rate;

                const itemDiscount = itemSubtotal * (item.discount / 100);

                const validDiscount = Math.min(itemDiscount, itemSubtotal);

                const taxableAmount = itemSubtotal;

                const itemTax = taxableAmount * (item.taxRate / 100);

                acc.subtotal += parseFloat(itemSubtotal.toFixed(2));
                acc.discountAmount += parseFloat(validDiscount.toFixed(2));
                acc.taxAmount += parseFloat(itemTax.toFixed(2));

                return acc;
            },
            { subtotal: 0, discountAmount: 0, taxAmount: 0 }
        );
        const netPayable = parseFloat((totals.subtotal - totals.discountAmount + totals.taxAmount).toFixed(2));
        setOrder((prevOrder) => ({
            ...prevOrder,
            totalSubTotal: totals.subtotal,
            totalDiscount: totals.discountAmount,
            totalTax: totals.taxAmount,
            netPayable,
        }));
    };

    useEffect(() => {
        calculateTotal();
    }, [order.items]);


    const sendInvoice = async (order) => {
        try {
            const result = await axiosPostCall("sendInvoice", { formData: order });
            if (result.message === 'Invoice sent successfully') {
                enqueueSnackbar('Quotation created successfully!', {
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'success',
                  });
                navigate("/billing")
                setOrder(prevOrder => ({
                    ...prevOrder,
                    toAddress: {
                        fullName: '',
                        email: '',
                        contactNumber: '',
                        address: '',
                        city: '',
                        state: '',
                        country: '',
                        zipCode: ''
                    },
                    items: [{
                        product: '',
                        plan: '',
                        quantity: '',
                        rate: '',
                        taxRate: '',
                        taxAmount: '',
                        discount: '',
                        discountAmount: '',
                        amount: 0
                    }]
                }));
            }

        } catch (error) {
            console.error(error)

        }
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    };

    return (
        <>
            <Box
                // onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '25px',
                    width: '100%',
                    maxWidth: '1000px',
                    margin: 'auto',
                    padding: '25px',
                    boxShadow: 3,
                    borderRadius: '8px',
                }}
                noValidate
                autoComplete="off"
            >
                <Formik
                    initialValues={{
                        fromAddress: {
                            fullName: 'Sarv.com', email: 'Sales@sarv.com', contactNumber: '+91-9111-9111-00', address: 'IT-10, EPIP RIICO Industrial Area, Sitapura',
                            city: 'Jaipur', zipCode: '203022', state: 'Rajasthan', country: 'India'
                        },
                        toAddress: {
                            fullName: '', email: '', contactNumber: '', address: '',
                            city: '', zipCode: '', state: '', country: ''
                        }
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setOrder((prev) => ({
                            ...prev,
                            fromAddress: values.fromAddress,
                            toAddress: values.toAddress,
                        }));
                        sendInvoice(order)

                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form
                            style={{ width: '100%', gap: 1 }}


                        >
                            <Box

                                sx={{ width: '100%', gap: 1, padding: '0 5px' }}>
                                <h4 style={{ marginTop: '20px ' }}>From</h4>
                                <RowTow sx={{ display: 'flex', width: '100%' }}>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Full Name *"
                                        name="fromAddress.fullName"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.fullName && errors.fromAddress?.fullName}
                                        error={touched.fromAddress?.fullName && Boolean(errors.fromAddress?.fullName)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.fullName', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, fullName: value },
                                            }));
                                        }}
                                        value={values.fromAddress.fullName}
                                    />


                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Email *"
                                        name="fromAddress.email"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.email && errors.fromAddress?.email}
                                        error={touched.fromAddress?.email && Boolean(errors.fromAddress?.email)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.email', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, email: value },
                                            }));
                                        }}
                                        value={values.fromAddress.email}
                                    />

                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Contact Number *"
                                        name="fromAddress.contactNumber"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.contactNumber && errors.fromAddress?.contactNumber}
                                        error={touched.fromAddress?.contactNumber && Boolean(errors.fromAddress?.contactNumber)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.contactNumber', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, contactNumber: value },
                                            }));
                                        }}
                                        value={values.fromAddress.contactNumber}
                                    />


                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Address *"
                                        name="fromAddress.address"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.address && errors.fromAddress?.address}
                                        error={touched.fromAddress?.address && Boolean(errors.fromAddress?.address)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.address', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, address: value },
                                            }));
                                        }}
                                        value={values.fromAddress.address}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="City *"
                                        name="fromAddress.city"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.city && errors.fromAddress?.city}
                                        error={touched.fromAddress?.city && Boolean(errors.fromAddress?.city)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.city', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, city: value },
                                            }));
                                        }}
                                        value={values.fromAddress.city}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Zip/Code *"
                                        name="fromAddress.zipCode"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.zipCode && errors.fromAddress?.zipCode}
                                        error={touched.fromAddress?.zipCode && Boolean(errors.fromAddress?.zipCode)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.zipCode', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, zipCode: value },
                                            }));
                                        }}
                                        value={values.fromAddress.zipCode}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="State *"
                                        name="fromAddress.state"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.state && errors.fromAddress?.state}
                                        error={touched.fromAddress?.state && Boolean(errors.fromAddress?.state)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.state', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, state: value },
                                            }));
                                        }}
                                        value={values.fromAddress.state}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Country *"
                                        name="fromAddress.country"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.country && errors.fromAddress?.country}
                                        error={touched.fromAddress?.country && Boolean(errors.fromAddress?.country)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('fromAddress.country', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                fromAddress: { ...prev.fromAddress, country: value },
                                            }));
                                        }}
                                        value={values.fromAddress.country}
                                    />
                                </RowTow>
                            </Box>

                            <Box sx={{ width: '100%', gap: 1, padding: '0 5px' }}>
                                <h4 style={{ marginTop: '20px' }}>To</h4>
                                <RowTow sx={{ display: 'flex', width: '100%' }}>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Full Name *"
                                        name="toAddress.fullName"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.fullName && errors.toAddress?.fullName}
                                        error={touched.toAddress?.fullName && Boolean(errors.toAddress?.fullName)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.fullName', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, fullName: value },
                                            }));
                                        }}
                                        value={values.toAddress.fullName}
                                    />


                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Email *"
                                        name="toAddress.email"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.email && errors.toAddress?.email}
                                        error={touched.toAddress?.email && Boolean(errors.toAddress?.email)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.email', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, email: value },
                                            }));
                                        }}
                                        value={values.toAddress.email}
                                    />

                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Contact Number *"
                                        name="toAddress.contactNumber"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.contactNumber && errors.toAddress?.contactNumber}
                                        error={touched.toAddress?.contactNumber && Boolean(errors.toAddress?.contactNumber)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.contactNumber', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, contactNumber: value },
                                            }));
                                        }}
                                        value={values.toAddress.contactNumber}
                                    />


                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Address *"
                                        name="toAddress.address"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.address && errors.toAddress?.address}
                                        error={touched.toAddress?.address && Boolean(errors.toAddress?.address)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.address', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, address: value },
                                            }));
                                        }}
                                        value={values.toAddress.address}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="City *"
                                        name="toAddress.city"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.city && errors.toAddress?.city}
                                        error={touched.toAddress?.city && Boolean(errors.toAddress?.city)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.city', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, city: value },
                                            }));
                                        }}
                                        value={values.toAddress.city}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Zip/Code *"
                                        name="toAddress.zipCode"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.zipCode && errors.toAddress?.zipCode}
                                        error={touched.toAddress?.zipCode && Boolean(errors.toAddress?.zipCode)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.zipCode', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, zipCode: value },
                                            }));
                                        }}
                                        value={values.toAddress.zipCode}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="State *"
                                        name="toAddress.state"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.state && errors.toAddress?.state}
                                        error={touched.toAddress?.state && Boolean(errors.toAddress?.state)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.state', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, state: value },
                                            }));
                                        }}
                                        value={values.toAddress.state}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Country *"
                                        name="toAddress.country"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.country && errors.toAddress?.country}
                                        error={touched.toAddress?.country && Boolean(errors.toAddress?.country)}
                                        sx={{ marginBottom: 1 }}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue('toAddress.country', value);
                                            setOrder((prev) => ({
                                                ...prev,
                                                toAddress: { ...prev.toAddress, country: value },
                                            }));
                                        }}
                                        value={values.toAddress.country}
                                    />
                                </RowTow>
                            </Box>

                            <div>
                                <h4>Items</h4>

                                {order.items.map((item, index) => {
                                    const selectableValues = Array.from({ length: 3 }, (_, i) => i + 1);
                                    const selectableTax = Array.from({ length: 3 }, (_, i) => i * 9);
                                    return (
                                        <div key={index} style={{ marginBottom: '15px', position: 'relative' }}>
                                            <RowTow>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel>Products</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        label="Product"
                                                        variant="outlined"
                                                        name="product"
                                                        value={item.product}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                    >
                                                        {Object.keys(rows).map((productName, prodIndex) => (
                                                            <MenuItem key={prodIndex} value={productName}>
                                                                {productName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel>Plan</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        label="Plan"
                                                        variant="outlined"
                                                        name="plan"
                                                        value={item.plan}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        disabled={!item.product}
                                                    >
                                                        {item.product && rows[item.product]?.map((plan, planIndex) => (
                                                            <MenuItem key={planIndex} value={plan.plan_name}>
                                                                {plan.plan_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    fullWidth
                                                    label="Unit Price"
                                                    variant="outlined"
                                                    type="number"
                                                    name="rate"
                                                    value={item.rate}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    InputProps={{ readOnly: true }}
                                                />
                                                <Autocomplete
                                                    freeSolo
                                                    clearOnEscape
                                                    options={selectableValues.map((value) => value.toString())}
                                                    value={item.quantity.toString()}
                                                    onInputChange={(event, newInputValue) => {
                                                        handleInputChange(index, { target: { name: "quantity", value: newInputValue } });
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        handleInputChange(index, { target: { name: "quantity", value: newValue || "" } });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label="Quantity"
                                                            variant="outlined"
                                                            type="text"
                                                            onFocus={() => setIsFocused(true)}
                                                            onBlur={() => setIsFocused(false)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: isFocused ? params.InputProps.endAdornment : null,
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    freeSolo
                                                    clearOnEscape
                                                    options={selectableTax.map((value) => value.toString())}
                                                    value={item.taxRate.toString()}
                                                    onInputChange={(event, newInputValue) => {
                                                        handleInputChange(index, { target: { name: "taxRate", value: newInputValue } });
                                                    }}
                                                    onChange={(event, newValue) => {
                                                        handleInputChange(index, { target: { name: "taxRate", value: newValue || "" } });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label="Tax(%)"
                                                            variant="outlined"
                                                            type="text"
                                                            onFocus={() => setIsFocused(true)}
                                                            onBlur={() => setIsFocused(false)}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: isFocused ? params.InputProps.endAdornment : null,
                                                            }}
                                                        />
                                                    )}
                                                />

                                                <TextField
                                                    fullWidth
                                                    label="Disc (%)"
                                                    variant="outlined"
                                                    InputProps={{ sx: inputSx }}
                                                    type="number"
                                                    name="discount"
                                                    value={item.discount}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                />
                                                <TextField
                                                    key={index}
                                                    fullWidth
                                                    label="Amount(₹)"
                                                    variant="outlined"
                                                    InputProps={{
                                                        sx: inputSx

                                                    }}
                                                    type="number"
                                                    name="amount"
                                                    value={order.items[index]?.amount?.toFixed(2) || 0}
                                                    onChange={(e) => {
                                                        const updatedItems = [...order.items];
                                                        const newAmount = parseFloat(e.target.value) || 0;
                                                        updatedItems[index] = {
                                                            ...updatedItems[index],
                                                            amount: newAmount,
                                                        };
                                                        setOrder({ ...order, items: updatedItems });
                                                    }}
                                                />
                                                <Tooltip title="Delete Item">
                                                    <IconButton
                                                        onClick={() => handleRemoveItem(index)}
                                                        sx={{ ml: 0.5 }}
                                                    >
                                                        <Iconify icon="solar:trash-bin-trash-bold" />
                                                    </IconButton>
                                                </Tooltip>
                                            </RowTow>

                                        </div>
                                    )
                                })}
                                <Tooltip title="Add Item">
                                    <IconButton
                                        onClick={() =>
                                            setOrder({
                                                ...order,
                                                items: [...order.items, { product: '', plan: '', quantity: '', rate: '', taxRate: '', taxAmount: 0, discount: '', discountAmount: 0, amount: 0 }]
                                            })
                                        }
                                        sx={{
                                            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
                                            border: (theme) => `dashed 1px ${theme.palette.divider}`,
                                        }}
                                    >
                                        <Iconify icon="mingcute:add-line" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div style={{ width: '100%' }}>
                                <Box sx={{ width: '100%' }}>
                                    {[
                                        { label: 'Subtotal(₹):', value: order.totalSubTotal.toFixed(2), isBold: true },
                                        { label: 'Discount(₹):', value: order.totalDiscount.toFixed(2) },
                                        { label: 'Tax(₹):', value: order.totalTax.toFixed(2) }
                                    ].map((item, index) => (
                                        <div key={index} style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', margin: '5px' }}>
                                            <div style={{ textAlign: 'right', width: '80%' }}>
                                                <span style={{ fontWeight: item.isBold ? 'bold' : 'normal' }}>{item.label}</span>
                                            </div>
                                            <div style={{ textAlign: 'right', width: '20%' }}>
                                                <span>{formatCurrency(item.value)}</span>
                                            </div>
                                            <Divider sx={{ my: 0.5, borderBottomWidth: 1, flex: 1 }} />
                                        </div>
                                    ))}

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                                        <div style={{ flexGrow: 1 }} />
                                        <Divider sx={{ my: 0.5, borderBottomWidth: 1, width: '28%', marginLeft: 'auto' }} />
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                        <div style={{ textAlign: 'right', width: '80%' }}>
                                            <span style={{ fontWeight: 'bold' }}>Total:</span>
                                        </div>
                                        <div style={{ textAlign: 'right', width: '20%' }}>
                                            <span>₹ {formatCurrency(order.netPayable.toFixed(2))}</span>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                pt: '10px',
                                pb: '20px',
                                gap: "15px"
                            }}>
                                <LoadingButton
                                    variant='outlined'
                                    onClick={() => confirm.onTrue()}
                                >
                                    Preview
                                </LoadingButton>
                                <LoadingButton
                                    type='submit'
                                    variant='outlined'
                                >
                                    Send
                                </LoadingButton>
                            </Box>
                        </Form>
                    )}
                </Formik>




            </Box>

            <PreviewComponent
                open={confirm.value}
                setOpen={confirm.onFalse}
                order={order}
            />
        </>
    );
};

export default BillingForm;
