// @mui 
import { Paper, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";

// @mui icons
import PeopleIcon from '@mui/icons-material/People';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import { useNavigate } from "react-router-dom";
import { checkRouteAccess } from "../../utils/common";
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';

const CustomMenuSection = styled('div')(() => ({
    flexDirection: "column",
    position: "fixed",
    left: 65,
    minWidth: "72px",
    zIndex: 100,
    color: "#637381"
}))

const MenuPaper = styled(Paper)(({ }) => ({
    width: 170,
    height: 176,
    textAlign: 'center',
    ml: '12px',
    mt: '-10px',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    // '&:before': {
    //     content: '""',
    //     display: 'block',
    //     position: 'absolute',
    //     top: 32,
    //     right: 0,
    //     left: -5,
    //     width: 10,
    //     height: 10,
    //     backgroundColor: '#fff',
    //     transform: 'translateY(-50%) rotate(45deg)',
    //     zIndex: 0,
    // },
}));

const SideBarHoverMenu = ({ custombox }) => {
    const navigate = useNavigate()
    return (
        <CustomMenuSection sx={{
            display: custombox ? "flex" : "none",
        }}>
            <MenuPaper elevation={20}>
                <List sx={{
                    marginTop: "5px",
                    padding: "0px",
                }}>
                    {checkRouteAccess("/console_users") && <ListItem disablePadding sx={{
                        margin: "0px"
                    }}>
                        <ListItemButton onClick={() => navigate("/console_users")}>
                            <ListItemIcon sx={{
                                // minWidth: "34px",
                                color: "grey.600",
                            }}>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Users" sx={{ lineHeight: 1, margin: 0, color: "grey.600", }} />
                        </ListItemButton>
                    </ListItem>}

                    {checkRouteAccess("/advertisement") && <ListItem disablePadding >
                        <ListItemButton component="a" onClick={() => navigate("/advertisement")}>
                            <ListItemIcon sx={{
                                // minWidth: "auto",
                                color: "grey.600",
                            }}>
                                <PlaylistAddCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Advertisement" sx={{ lineHeight: 1, margin: 0, color: "grey.600", }} />
                        </ListItemButton>
                    </ListItem>}
                    {checkRouteAccess("/branding") && <ListItem disablePadding >
                        <ListItemButton component="a" onClick={() => navigate("/branding")}>
                            <ListItemIcon sx={{
                                color: "grey.600",
                            }}>
                                <CollectionsBookmarkIcon />
                            </ListItemIcon>
                            <ListItemText primary="Branding" sx={{ lineHeight: 1, margin: 0, color: "grey.600", }} />
                        </ListItemButton>
                    </ListItem>}
                    {/* {checkRouteAccess("/amAnalysis") &&  */}
                    <ListItem disablePadding >
                        <ListItemButton component="a" onClick={() => navigate("/amAnalysis")}>
                            <ListItemIcon sx={{
                                color: "grey.600",
                            }}>
                                <BarChartRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="AM analysis" sx={{ lineHeight: 1, margin: 0, color: "grey.600", }} />
                        </ListItemButton>
                    </ListItem>
                    {/* } */}
                </List>
            </MenuPaper>
        </CustomMenuSection>

    )
}
export default SideBarHoverMenu;