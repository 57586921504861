import React from 'react';
import Switch from '@mui/material/Switch';

// @mui
import { Typography,  Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const PagesRowSection = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px'
}));

const ActionTitle = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 500,
    color: "#727374",
}));

const AccessTab = ({ accessArray, tab, handlePermissionChange, adminAccess }) => {
    
    const handleAllCheckedChange = (event) => {
        tab?.child.map((id) => {
            accessArray[id] && handleBothChange(id, event.target.checked);
        })
    };

    const allCheckHandle = () => {
        const checkArray = tab?.child.map((id) => {
            return accessArray[id]?.["r"] && accessArray[id]?.["w"]
        })
        if (checkArray.includes(false)) return false;
        return true;
    }

    const handleBothChange = (id, bool) => {
        adminAccess[id]?.r ? handlePermissionChange(id, "r", bool) : ""
        adminAccess[id]?.w ? handlePermissionChange(id, "w", bool) : ""
    }
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    return (
        <>
            
            {tab?.child?.length > 0 && <PagesRowSection>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%'
                }}>
                    <Checkbox checked={allCheckHandle()}
                        onChange={(e) => handleAllCheckedChange(e)}
                        inputProps={{ 'aria-label': 'Checkbox' }} size='small' />
                    <Typography sx={{ fontSize: '14px' }}>
                        Check here to allow all access for this page
                    </Typography>
                </Box>

                <TableContainer component={Paper} sx={{ borderRadius: '0px' }}>
                    <Table sx={{ minWidth: 350, width: 590 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ maxWidth: 200, }}>Name</TableCell>
                                <TableCell align="center" style={{ maxWidth: 10, }}>Read</TableCell>
                                <TableCell align="center" style={{ maxWidth: 10, }}>Write</TableCell>
                                <TableCell align="center" style={{ maxWidth: 30, }}>Both</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tab?.child.map((id) => (
                                accessArray[id] && <TableRow
                                    key={id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <ActionTitle>
                                            {accessArray[id].title}
                                        </ActionTitle>

                                    </TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <AntSwitch
                                                checked={accessArray[id].r}
                                                onChange={(e) => handlePermissionChange(id, "r", e.target.checked)}
                                                disabled={!adminAccess[id].r}
                                                inputProps={{ 'aria-label': 'ant design' }} />
                                               
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <AntSwitch
                                                checked={accessArray[id].w}
                                                onChange={(e) => handlePermissionChange(id, "w", e.target.checked)}
                                                disabled={!adminAccess[id].w}
                                                inputProps={{ 'aria-label': 'ant design' }} />
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <AntSwitch checked={accessArray[id].r && accessArray[id].w}
                                                onChange={(e) => handleBothChange(id, e.target.checked)}
                                                disabled={!adminAccess[id].r || !adminAccess[id].w}

                                                inputProps={{ 'aria-label': 'ant design' }} />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </PagesRowSection>
            }
        </>

    )
}

export default AccessTab