import './App.css';
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

// theme
import ThemeProvider from './theme';

// Routes
import AllRoutes from './lib/Routes';

//context
import { PermissionContext } from './context/PermissionContext';
import { useEffect, useState } from 'react';
import { useSetPermission } from './hooks/useSetPermission';
import { SocketProvider } from '../src/context/SocketContext';
import { axiosGetCall } from './services/apisCall';
import { setItem } from './lib/cookies';
import { useAuth } from './hooks/useAuth';

function App() {
  // Combine routes.
  const router = createBrowserRouter(createRoutesFromElements(AllRoutes()));
  const [isTokenFetch, setIsTokenFetch] = useState(false);
  const [tokenValue,setTokenValue] = useState(null)
  const [permission, setPermission] = useState({});
  useSetPermission(setPermission);

  const fetchtoken = async () => {
    try {
      if (!useAuth()) {
        return setIsTokenFetch(true);
      }
      const { token } = await axiosGetCall("fetchToken");
      localStorage.setItem("token", token)
      setIsTokenFetch(true);
      if(token) setTokenValue(true)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchtoken()
  }, [])

  return (
    isTokenFetch ? <HelmetProvider>
      <PermissionContext.Provider value={{ permission, setPermission }} >
        <ThemeProvider mode={'light'}>
          <QueryClientProvider client={queryClient}>
            <SocketProvider tokenValue={tokenValue}>
              <RouterProvider router={router} />
            </SocketProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </PermissionContext.Provider>
    </HelmetProvider> :
      <>
      </>
  );
}

export default App;
