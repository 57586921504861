import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, LinearProgress, CircularProgress } from '@mui/material';

const { configFile } = require("src/config");
const { endPoints } = configFile;

const RecordingProgressModal = ({ show, onHide, url, annId }) => {
    const [stepsData, setStepsData] = useState({
        Downloading: { status: '', data: '', step: '' },
        'Detecting Language': { status: '', data: '', step: '' },
        'Converting to Text': { status: '', data: '', step: '' },
        'Translating to English': { status: '', data: '', step: '' }
    });
    const [currentStep, setCurrentStep] = useState(0);
    const [totalSteps] = useState(4);
    const [isLoading, setIsLoading] = useState(true);

    const indianLanguageMapper = {
        as: "Assamese",
        bn: "Bengali",
        gu: "Gujarati",
        hi: "Hindi",
        kn: "Kannada",
        ml: "Malayalam",
        mr: "Marathi",
        or: "Odia",
        pa: "Punjabi",
        ta: "Tamil",
        te: "Telugu",
        ur: "Urdu",
        en: "English",
        ks: "Kashmiri",
        kok: "Konkani",
        mni: "Manipuri (Meitei)",
        ne: "Nepali",
        sa: "Sanskrit",
        si: "Sinhala",
        sd: "Sindhi",
        bh: "Bhojpuri",
        bo: "Bodo",
        doi: "Dogri",
        mai: "Maithili",
        sat: "Santali",
        lmn: "Limbu",
        new: "Newar",
        lep: "Lepcha",
        brx: "Bodo (Brx)",
        syl: "Sylheti",
        pdc: "Pahari (Dogri-Chamba)",
        sgr: "Sora",
    };

    // Example usage:
    const getLanguageName = (code) => {
        return indianLanguageMapper[code] || "Unknown Language";
    };

    useEffect(() => {
        if (show) {

            const apiUrl = endPoints("getTranslatedText");
            const eventSource = new EventSource(`${apiUrl}?url=${url}&annId=${annId}`);

            eventSource.onmessage = (event) => {
                const stepData = JSON.parse(event.data);

                setStepsData((prevData) => ({
                    ...prevData,
                    [stepData.step]: { status: stepData.status, data: stepData.data, step: stepData.step }
                }));

                if (stepData.step !== 'Completed') {
                    setCurrentStep((prev) => prev + 1);
                }

                if (stepData.step === 'Completed') {
                    setIsLoading(false);
                    eventSource.close();
                }
            };

            eventSource.onerror = () => {
                console.error('Error occurred while receiving SSE');
                eventSource.close();
            };

            return () => {
                eventSource.close();
            };
        }
    }, []);

    return (
        <Modal open={show} onClose={onHide} aria-labelledby="progress-modal-title" aria-describedby="progress-modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 3,
                }}
            >

                <Typography id="progress-modal-title" variant="h6" component="h2" gutterBottom>
                    Recording Processing
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', gap: '10px', marginTop: "10px",marginBottom:"10px" }}>
                    <audio controls src={url} style={{ height: 38 }} >
                    </audio>
                </Box>
                <Typography variant="subtitle1" gutterBottom>
                    {stepsData.Downloading.step}
                </Typography>
                <Typography variant="body2" paragraph>
                    {stepsData.Downloading.data}
                </Typography>
                {stepsData['Detecting Language'].step && <>
                    <Typography variant="subtitle1" gutterBottom>
                        {stepsData['Detecting Language'].step}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {getLanguageName(stepsData['Detecting Language'].data)}
                    </Typography>

                </>}

                <Typography variant="subtitle1" gutterBottom>
                    {stepsData['Converting to Text'].step}
                </Typography>
                <Typography variant="body2" paragraph>
                    {stepsData['Converting to Text'].data}
                </Typography>

                <Typography variant="subtitle1" gutterBottom>
                    {stepsData['Translating to English'].step}
                </Typography>
                <Typography variant="body2" paragraph>
                    {stepsData['Translating to English'].data}
                </Typography>

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Process Completed!
                        </Typography>
                    </Box>
                )}

                <LinearProgress sx={{ mt: 2 }} variant="determinate" value={(currentStep / totalSteps) * 100} />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant="contained" onClick={onHide}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RecordingProgressModal;
